import { ApiInstance } from 'services/api/api-instance';
import { ApiResponse, ApisauceInstance } from 'apisauce';
import {
  AddLifestylePhotoRequest,
  GetProductResponse,
  GetProductsResponse,
  GetSkusResponse,
  GetStatsRequest,
  GetStatsResponse,
  LaunchRequest,
  RemoveLifestylePhotoRequest,
  UpdateAsSeenOnRequest,
  UpdateAsSeenOnResponse,
} from 'services/api';
import { stringify } from 'query-string';

export class ProductApi extends ApiInstance {
  constructor(instance: ApisauceInstance) {
    super(instance, '/products');
  }

  async getSkus(): Promise<GetSkusResponse> {
    const response: ApiResponse<GetSkusResponse> = await this.api.get(
      '/products/skus',
    );

    return this.handleResponse<GetSkusResponse>(response);
  }

  async getProducts(): Promise<GetProductsResponse> {
    const response: ApiResponse<GetProductsResponse> = await this.api.get(
      '/graphite/products',
    );

    return this.handleResponse<GetProductsResponse>(response);
  }

  async getProduct(id: number): Promise<GetProductResponse> {
    const response: ApiResponse<GetProductResponse> = await this.api.get(
      `/user/products/${id}/info`,
    );

    return this.handleResponse<GetProductResponse>(response);
  }

  async getStats(request: GetStatsRequest): Promise<GetStatsResponse> {
    const response: ApiResponse<GetStatsResponse> = await this.api.get(
      `/products/stats?${stringify(request)}`,
    );

    return this.handleResponse<GetStatsResponse>(response);
  }

  async updateAsSeenOn(
    id: number,
    request: UpdateAsSeenOnRequest,
  ): Promise<UpdateAsSeenOnResponse> {
    const response: ApiResponse<UpdateAsSeenOnResponse> = await this.api.put(
      `/user/products/${id}/as-seen-on`,
      request,
    );

    return this.handleResponse<UpdateAsSeenOnResponse>(response);
  }

  async addLifestylePhoto(
    id: number,
    request: AddLifestylePhotoRequest,
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.put(
      `/admin/products/${id}/addImg`,
      request,
    );

    return this.handleResponse<void>(response);
  }

  async removeLifestylePhoto(
    id: number,
    request: RemoveLifestylePhotoRequest,
  ): Promise<void> {
    const response: ApiResponse<void> = await this.api.post(
      `/admin/products/${id}/deleteImg`,
      request,
    );

    return this.handleResponse<void>(response);
  }

  async launch(request: LaunchRequest): Promise<void> {
    const response: ApiResponse<void> = await this.api.put(
      'user/products/launch',
      request,
    );

    return this.handleResponse<void>(response);
  }
}
